<template>
  <v-dialog :value="true" width="500" persistent>
    <v-card :loading="loading" class="pt-4">
      <v-list>
        <v-list-item
          ><v-textarea
            :disabled="loading"
            outlined
            v-model="item.title"
            label="Titel"
            hint="Längenbegrenzung wegen Zeugniseintrag"
            persistent-hint
            counter="100"
          ></v-textarea
        ></v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-card-actions
        ><v-spacer></v-spacer>
        <v-btn text @click="close(false)"> abbrechen </v-btn>
        <v-btn color="primary" text @click="saveTitle()"> speichern </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      loading: false,
      item: {},
      saving: false,
    };
  },
  methods: {
    close(update) {
      this.$router.push({ name: 'Thesis', params: { update: update } });
    },
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: 'thesis/thesis',
        id: this.id,
      });
      this.loading = false;
    },
    async saveTitle() {
      this.saving = true;
      await this.apiPatch({
        resource: 'thesis/thesis',
        id: this.item.id,
        key: 'title',
        value: this.item.title,
      });
      this.close(true);
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
